import React from 'react'
import { Tabs, Tab } from 'react-bootstrap'
import styled from 'styled-components'

const Wrapper = styled.div`
  font-size: .85em;
`

export default function Programme() {
  return (
    <Wrapper>
      <Tabs defaultActiveKey="day2">
        <Tab eventKey="day1" title="Day 1 - Sept, 5th">
          <table className="table table-bordered table-hover table-responsive">
            <tbody>
              <tr>
                <td>
                  <p className="mb-2">
                    <strong>TIME</strong>
                  </p>
                </td>
                <td>
                  <p className="mb-2">
                    <strong>VIRTUAL HALL 1</strong>
                  </p>
                </td>
                <td>
                  <p className="mb-2">
                    <strong> VIRTUAL HALL 2</strong>
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="mb-2">0800-0815</p>
                </td>
                <td>
                  <p className="mb-2">Sponsored Videos</p>
                </td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>
                  <p className="mb-2">
                    <strong>0815-0900</strong>
                  </p>
                  <p className="mb-2">0815-0845</p>
                  <p className="mb-2">0845-0900</p>
                </td>
                <td>
                  <p className="mb-2">
                    <strong>Symposium 1</strong>
                  </p>
                  <p className="mb-2">
                    <strong>GE Sponsored Symposium</strong>
                  </p>
                  <p className="mb-2">Moderator: Dr Voon Hian Yan</p>
                  <p className="mb-2">
                    <strong>
                      Cerebroplacental Ratio and Late Fetal Growth Restriction{' '}
                    </strong>
                  </p>
                  <p className="mb-2">
                    <em>Prof Sailesh Kumar</em>
                  </p>
                  <p className="mb-2">Q&amp;A</p>
                </td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>
                  <p className="mb-2">
                    <strong>090</strong>
                    <strong>0</strong>
                    <strong>-0</strong>
                    <strong>9</strong>
                    <strong>15</strong>
                  </p>
                </td>
                <td>
                  <p className="mb-2">
                    <strong>O</strong>
                    <strong>p</strong>
                    <strong>e</strong>
                    <strong>n</strong>
                    <strong>i</strong>
                    <strong>n</strong>
                    <strong>g</strong>
                    <strong>&amp;</strong>
                    <strong>Wel</strong>
                    <strong>co</strong>
                    <strong>me</strong>
                    <strong>A</strong>
                    <strong>ddr</strong>
                    <strong>es</strong>
                    <strong>s</strong>
                  </p>
                  <p className="mb-2">
                    <em>D</em>
                    <em>r </em>
                    <em>Ha</em>
                    <em>r</em>
                    <em>r</em>
                    <em>i</em>
                    <em>s</em>
                    <em> Njoo </em>
                    <em>Suha</em>
                    <em>r</em>
                    <em>jon</em>
                    <em>o</em>
                  </p>
                  <p className="mb-2">
                    <em>O</em>
                    <em>G</em>
                    <em>SM </em>
                    <em>P</em>
                    <em>r</em>
                    <em>esi</em>
                    <em>d</em>
                    <em>e</em>
                    <em>n</em>
                    <em>t</em>
                  </p>
                </td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>
                  <p className="mb-2">
                    <strong>0915-0</strong>
                    <strong>9</strong>
                    <strong>45</strong>
                  </p>
                  <p className="mb-2">
                    <strong>0945</strong>
                    <strong>-1</strong>
                    <strong>0</strong>
                    <strong>15</strong>
                  </p>
                  <p className="mb-2">1015-1030</p>
                </td>
                <td>
                  <p className="mb-2">
                    <strong>OGSM Plenary 1</strong>
                  </p>
                  <p className="mb-2">Moderator: Dr Patricia Lim Su-Lyn</p>
                  <p className="mb-2">
                    <strong>Presidential Lecture: COVID-19: World War III</strong>
                  </p>
                  <p className="mb-2">
                    <em>D</em>
                    <em>r </em>
                    <em>Ha</em>
                    <em>r</em>
                    <em>r</em>
                    <em>i</em>
                    <em>s</em>
                    <em> Njoo </em>
                    <em>Suha</em>
                    <em>r</em>
                    <em>jon</em>
                    <em>o</em>
                  </p>
                  <p className="mb-2">
                    <em>O</em>
                    <em>G</em>
                    <em>SM </em>
                    <em>P</em>
                    <em>r</em>
                    <em>esi</em>
                    <em>d</em>
                    <em>e</em>
                    <em>n</em>
                    <em>t</em>
                  </p>
                  <p className="mb-2">
                    <strong>OGSM Plenary 2</strong>
                  </p>
                  <p className="mb-2">Moderator: Dr Patricia Lim Su-Lyn</p>
                  <p className="mb-2">
                    <strong>COVID-19: The Rise of our Fraternity </strong>
                  </p>
                  <p className="mb-2">
                    <em>YBhg Datuk Dr Hj Rohaizat Bin Hj Yon</em>
                  </p>
                  <p className="mb-2">
                    <em>
                      Deputy Director-General (Medical) Ministry of Health
                      Malaysia
                    </em>
                  </p>
                  <p className="mb-2">Q&amp;A</p>
                </td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>
                  <p className="mb-2">1030-1045</p>
                </td>
                <td>
                  <p className="mb-2">Break &amp;Sponsored Videos</p>
                </td>
                <td rowspan="3">
                  <p className="mb-2">1030 &ndash; 1115</p>
                  <p className="mb-2">
                    <strong>Poster Presentations - Obstetrics</strong>
                  </p>
                  <p className="mb-2">
                    <em>(5 min per presentation, 2 min Q&amp;A)</em>
                  </p>
                  <p className="mb-2">
                    <strong>POB 01 </strong>
                  </p>
                  <p className="mb-2">
                    <strong>
                      Shoulder Dystocia in O&amp;G Department Hospital Sultanah
                      Bahiyah: A 4-year Review{' '}
                    </strong>
                  </p>
                  <p className="mb-2">
                    <em>Dr Izzat Hafifie Bin Hazmi</em>
                  </p>
                  <p className="mb-2">
                    <strong>POB 02</strong>
                  </p>
                  <p className="mb-2">
                    <strong>
                      Prediction Successful Prostaglandin (PGE2) Induction of
                      Labour in Primiparous Term Pregnancy by Measuring Cervical
                      Length using Transvaginal Ultrasound
                    </strong>
                  </p>
                  <p className="mb-2">
                    <em>Dr Syahrul Azwan Bin Azhari</em>
                  </p>
                  <p className="mb-2">
                    <strong>POB 03</strong>
                  </p>
                  <p className="mb-2">
                    <strong>
                      BabyBaru: Application of Artificial Intelligence in
                      Pregnancy during a Pandemic
                    </strong>
                  </p>
                  <p className="mb-2">
                    <em>Dr Abigail Jerip</em>
                  </p>
                  <p className="mb-2">
                    <strong>POB 04</strong>
                  </p>
                  <p className="mb-2">
                    <strong>
                      Comparison between Clinical versus Ultrasound Estimate of
                      Fetal Weight at Term
                    </strong>
                  </p>
                  <p className="mb-2">
                    <em>Assoc Prof Shuhaila Ahmad</em>
                  </p>
                  <p className="mb-2">
                    <strong>POB 05</strong>
                  </p>
                  <p className="mb-2">
                    <strong>Improvement of Night Sleep and Vaginal</strong>
                  </p>
                  <p className="mb-2">
                    <strong>
                      Delivery in Nulliparas who used Eye Mask and Ear Plugs: A
                      Randomised Controlled Trial
                    </strong>
                  </p>
                  <p className="mb-2">
                    <em>Dr Vimaladevi Annamalai</em>
                  </p>
                </td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td rowspan="2">
                  <p className="mb-2">
                    <strong>1045-1130</strong>
                  </p>
                </td>
                <td rowspan="3">
                  <p className="mb-2">
                    <strong>S</strong>
                    <strong>ympo</strong>
                    <strong>s</strong>
                    <strong>i</strong>
                    <strong>u</strong>
                    <strong>m 2 &amp; 3</strong>
                  </p>
                  <p className="mb-2">
                    <strong>GSK</strong>
                    <strong>Spon</strong>
                    <strong>s</strong>
                    <strong>or</strong>
                    <strong>e</strong>
                    <strong>d Symposium</strong>
                  </p>
                  <p className="mb-2">Moderator: Dr Muralitharan Ganesalingam</p>
                  <p className="mb-2">
                    <strong>
                      Maternal Immunisation Protects Infant Pertussis
                    </strong>
                  </p>
                  <p className="mb-2">
                    <em>Dato&rsquo; Dr Musa Mohd Nordin </em>
                  </p>
                  <p className="mb-2">
                    <strong>
                      Concept of Maternal Immunisation with Tdap Vaccination,
                      Safety and Effectiveness Data
                    </strong>
                  </p>
                  <p className="mb-2">
                    <em>Dr Yap Moy Juan </em>
                  </p>
                  <p className="mb-2">Panel Discussion and Q&amp;A</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="mb-2">Break &amp;Sponsored Videos</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="mb-2">
                    <strong>1130-1215</strong>
                  </p>
                </td>
                <td>
                  <p className="mb-2">1115 &ndash; 1230</p>
                  <p className="mb-2">
                    <strong>Poster Presentations - Gynaecology</strong>
                  </p>
                  <p className="mb-2">
                    <em>(5 min per presentation, 2 min Q&amp;A)</em>
                  </p>
                  <p className="mb-2">
                    <strong>PGY 01</strong>
                  </p>
                  <p className="mb-2">
                    <strong>Does Sperm Parameter Matters in ICSI?</strong>
                  </p>
                  <p className="mb-2">
                    <em>Dr Chua Ai Chen</em>
                  </p>
                  <p className="mb-2">
                    <strong>PGY 02</strong>
                  </p>
                  <p className="mb-2">
                    <strong>
                      Human Papillomavirus (HPV) Type Distribution in Cervical
                      Intraepithelial Neoplasia (CIN) Cases in Malaysia
                    </strong>
                  </p>
                  <p className="mb-2">
                    <em>Ms Su Pei Khoo</em>
                  </p>
                  <p className="mb-2">
                    <strong>PGY 03</strong>
                  </p>
                  <p className="mb-2">
                    <strong>
                      10 Years Review on Endometrial Cancer in Women Aged 45 Years
                      Old or Less in Hospital Sultanah Bahiyah, Alor Setar, Kedah
                    </strong>
                  </p>
                  <p className="mb-2">
                    <em>Dr Faridah binti Shamsudin</em>
                  </p>
                  <p className="mb-2">
                    <strong>PGY 04</strong>
                  </p>
                  <p className="mb-2">
                    <strong>
                      Unexplained infertility &ndash; Do Women's Age and Duration
                      of Fertility Matters?
                    </strong>
                  </p>
                  <p className="mb-2">
                    <em>Dr Norazilah binti Mat Jin</em>
                  </p>
                  <p className="mb-2">
                    <strong>PGY 05</strong>
                  </p>
                  <p className="mb-2">
                    <strong>
                      Prevalence of Cervico-vaginal Human Papillomavirus (HPV)
                      Infection among Malaysian Women Aged 18-24 and 35-45 a
                      Decade after the Implementation of the National School-based
                      HPV Vaccination Program
                    </strong>
                  </p>
                  <p className="mb-2">
                    <em>Ms Su Pei Khoo</em>
                  </p>
                </td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>
                  <p className="mb-2">
                    <strong>1215-1300</strong>
                  </p>
                  <p className="mb-2">1215-1245</p>
                  <p className="mb-2">1245-1300</p>
                </td>
                <td>
                  <p className="mb-2">
                    <strong>The Great Debate: </strong>
                  </p>
                  <p className="mb-2">
                    <strong>Patients Have Better Rights Than Physicians</strong>
                  </p>
                  <p className="mb-2">
                    <strong>Moderator:</strong> Dr Gunasegaran Rajan
                  </p>
                  <p className="mb-2">
                    <strong>
                      <em>Proponents</em>
                    </strong>
                    <em>: Dr Jamiyah Hassan &amp; Dr J Ravichandran </em>
                  </p>
                  <p className="mb-2">
                    <strong>
                      <em>Opponents:</em>
                    </strong>
                    <em> Mr Dhinesh Bhaskaran &amp; Ms Raja Eileen Soraya </em>
                  </p>
                  <p className="mb-2">Q&amp;A</p>
                </td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>
                  <p className="mb-2">1300-1315</p>
                </td>
                <td>
                  <p className="mb-2">Break and Sponsored Videos</p>
                </td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>
                  <p className="mb-2">
                    <strong>1315-1400</strong>
                  </p>
                  <p className="mb-2">1315-1345</p>
                  <p className="mb-2">1345-1400</p>
                </td>
                <td>
                  <p className="mb-2">
                    <strong>S</strong>
                    <strong>ympo</strong>
                    <strong>s</strong>
                    <strong>i</strong>
                    <strong>u</strong>
                    <strong>m 4</strong>
                  </p>
                  <p className="mb-2">
                    <strong>Bayer Sponsored Symposium</strong>
                  </p>
                  <p className="mb-2">Moderator: Dr Khong Su Yen</p>
                  <p className="mb-2">
                    <strong>
                      Endometriosis: Updates and Management Trends in Malaysia{' '}
                    </strong>
                  </p>
                  <p className="mb-2">
                    <em>Datuk Dr Soon Ruey</em>
                  </p>
                  <p className="mb-2">Q&amp;A</p>
                </td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>
                  <p className="mb-2">
                    <strong>1400-1445</strong>
                  </p>
                  <p className="mb-2">1400-1430</p>
                  <p className="mb-2">1430-1445</p>
                </td>
                <td>
                  <p className="mb-2">
                    <strong>OGSM Surgical Tutorial 1</strong>
                  </p>
                  <p className="mb-2">Moderator: Prof Ng Beng Kwang</p>
                  <p className="mb-2">
                    <strong>
                      Minimally Invasive Surgery: Intramural Fibroid and Fertility
                      &ndash; to Operate or not
                    </strong>
                  </p>
                  <p className="mb-2">
                    <em>Dr S Sevellaraja </em>
                  </p>
                  <p className="mb-2">Q&amp;A</p>
                </td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>
                  <p className="mb-2">
                    <strong>1445-1530</strong>
                  </p>
                  <p className="mb-2">1445-1515</p>
                  <p className="mb-2">1515-1530</p>
                </td>
                <td>
                  <p className="mb-2">
                    <strong>Symposium 5</strong>
                  </p>
                  <p className="mb-2">
                    <strong>Sanofi Sponsored Symposium </strong>
                  </p>
                  <p className="mb-2">Moderator: Dr Ravi Tiwari</p>
                  <p className="mb-2">
                    <strong>
                      Is VTE Prophylaxis needed for OBGYN Surgery? An Evidence
                      based Approach
                    </strong>
                  </p>
                  <p className="mb-2">
                    <em>Prof Tan Thiam Chye </em>
                  </p>
                  <p className="mb-2">Q&amp;A</p>
                </td>
                <td>
                  <p className="mb-2">
                    <strong>Symposium 6</strong>
                  </p>
                  <p className="mb-2">
                    <strong>Abbott Sponsored Symposium</strong>
                  </p>
                  <p className="mb-2">Moderator: Dr Eeson Sinthamoney</p>
                  <p className="mb-2">
                    <strong>
                      Progesterone Supplementation for Early Pregnancy Loss: The
                      Latest Clinical Updates
                    </strong>
                  </p>
                  <p className="mb-2">
                    <em>Prof Walfrido W Sumpaico </em>
                  </p>
                  <p className="mb-2">Q&amp;A</p>
                </td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>
                  <p className="mb-2">1530-1545</p>
                </td>
                <td>
                  <p className="mb-2">Break and Sponsored Videos</p>
                </td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>
                  <p className="mb-2">
                    <strong>1545-1615</strong>
                  </p>
                  <p className="mb-2">
                    <strong>1615-1645</strong>
                  </p>
                  <p className="mb-2">1645-1700</p>
                </td>
                <td>
                  <p className="mb-2">
                    <strong>OGSM Plenary 3</strong>
                  </p>
                  <p className="mb-2">Moderator: Dr Voon Hian Yan</p>
                  <p className="mb-2">
                    <strong>Preterm Prevention: What is New?</strong>
                  </p>
                  <p className="mb-2">
                    <em>Prof Jon Hyett&nbsp;</em>
                  </p>
                  <p className="mb-2">
                    <strong>OGSM Plenary 4</strong>
                  </p>
                  <p className="mb-2">Moderator: Dr Voon Hian Yan</p>
                  <p className="mb-2">
                    <strong>Connective Tissue Diseases in Pregnancy </strong>
                  </p>
                  <p className="mb-2">
                    <em>Prof Catherine Nelson-Piercy </em>
                  </p>
                  <p className="mb-2">Q&amp;A</p>
                </td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>
                  <p className="mb-2">1700-1715</p>
                </td>
                <td>
                  <p className="mb-2">Break and Sponsored Videos</p>
                </td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>
                  <p className="mb-2">
                    <strong>1715-1815</strong>
                  </p>
                </td>
                <td>
                  <p className="mb-2">
                    <strong>
                      OGSM Challenge: Search for the Malaysian Champion
                    </strong>
                  </p>
                  <p className="mb-2">Quizmaster: Dr Muniswaran Ganeshan</p>
                  <p className="mb-2">
                    <em>UM/UKM/USM/UIA/MRCOG</em>
                  </p>
                </td>
                <td>&nbsp;</td>
              </tr>
            </tbody>
          </table>
        </Tab>
        <Tab eventKey="day2" title="Day 2 - Sept, 6th">
          <table className="table table-bordered table-hover table-responsive">
            <tbody>
              <tr>
                <td>
                  <p className="mb-2">
                    <strong>T</strong>
                    <strong>i</strong>
                    <strong>me</strong>
                  </p>
                </td>
                <td>
                  <p className="mb-2">
                    <strong>VIRTUAL HALL 1</strong>
                  </p>
                </td>
                <td>
                  <p className="mb-2">
                    <strong>VIRTUAL </strong>
                    <strong>HALL 2</strong>
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="mb-2">0800-0830</p>
                </td>
                <td>
                  <p className="mb-2">Sponsored Videos</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="mb-2">
                    <strong>083</strong>
                    <strong>0</strong>
                    <strong>-0</strong>
                    <strong>9</strong>
                    <strong>15</strong>
                  </p>
                  <p className="mb-2">0830-0900</p>
                  <p className="mb-2">0900-0915</p>
                </td>
                <td>
                  <p className="mb-2">
                    <strong>Symposium 7</strong>
                  </p>
                  <p className="mb-2">
                    <strong>GE Sponsored Symposium </strong>
                  </p>
                  <p className="mb-2">Moderator: Dato&rsquo; Dr Bavanandan Naidu</p>
                  <p className="mb-2">
                    <strong>
                      The Obstetrician&rsquo;s Third Eye: Ultrasound in Obstetric
                      Emergencies
                    </strong>
                  </p>
                  <p className="mb-2">
                    <em>Dr Muniswaran Ganeshan </em>
                  </p>
                  <p className="mb-2">Q&amp;A</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="mb-2">
                    <strong>091</strong>
                    <strong>5</strong>
                    <strong>-1</strong>
                    <strong>0</strong>
                    <strong>30</strong>
                  </p>
                  <p className="mb-2">
                    <strong>091</strong>
                    <strong>5</strong>
                    <strong>-0</strong>
                    <strong>9</strong>
                    <strong>45</strong>
                  </p>
                  <p className="mb-2">
                    <strong>094</strong>
                    <strong>5</strong>
                    <strong>-1</strong>
                    <strong>0</strong>
                    <strong>15</strong>
                  </p>
                  <p className="mb-2">1015-1030</p>
                </td>
                <td>
                  <p className="mb-2">
                    <strong>OGSM-RANZCOG Symposium</strong>
                  </p>
                  <p className="mb-2">Moderator: Dr Harris Njoo Suharjono</p>
                  <p className="mb-2">
                    <strong>Plenary 5</strong>
                  </p>
                  <p className="mb-2">
                    <strong>
                      Maternal Mental Health: The Forgotten Obstetrics{' '}
                    </strong>
                  </p>
                  <p className="mb-2">
                    <em>Dr Vijay Roach </em>
                  </p>
                  <p className="mb-2">
                    <strong>Plenary 6</strong>
                  </p>
                  <p className="mb-2">
                    <strong>
                      Global Elimination of Cervical Cancer: How Far are we and
                      How do we Continue in the Face of the COVID-19 Pandemic?
                    </strong>
                  </p>
                  <p className="mb-2">
                    <em>Prof Marion Saville </em>
                  </p>
                  <p className="mb-2">Q&amp;A</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="mb-2">1030-1045</p>
                </td>
                <td>
                  <p className="mb-2">Break &amp; Sponsored Videos</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="mb-2">
                    <strong>104</strong>
                    <strong>5</strong>
                    <strong>-1</strong>
                    <strong>1</strong>
                    <strong>30</strong>
                  </p>
                  <p className="mb-2">1045-1115</p>
                  <p className="mb-2">1115-1130</p>
                </td>
                <td>
                  <p className="mb-2">
                    <strong>S</strong>
                    <strong>ympo</strong>
                    <strong>s</strong>
                    <strong>i</strong>
                    <strong>u</strong>
                    <strong>m 8</strong>
                  </p>
                  <p className="mb-2">
                    <strong>Sun Pharma </strong>
                    <strong>Spon</strong>
                    <strong>s</strong>
                    <strong>or</strong>
                    <strong>ed Symposium</strong>
                  </p>
                  <p className="mb-2">Moderator: Dr Ana Vetriana Abd Wahab</p>
                  <p className="mb-2">
                    <strong>
                      Vitamin D &ndash; Effects on Skeletal and Extra Skeletal
                      Health in Women
                    </strong>
                  </p>
                  <p className="mb-2">
                    <em>Dr Mohan Raj Varutha Rajoo </em>
                  </p>
                  <p className="mb-2">Q&amp;A</p>
                </td>
                <td>
                  <p className="mb-2">
                    <strong>S</strong>
                    <strong>ympo</strong>
                    <strong>s</strong>
                    <strong>i</strong>
                    <strong>u</strong>
                    <strong>m 9</strong>
                  </p>
                  <p className="mb-2">
                    <strong>Aspen Medical Sponsored Symposium</strong>
                  </p>
                  <p className="mb-2">Moderator: Dr Tham Seong Wai</p>
                  <p className="mb-2">
                    <strong>
                      A Smooth Descent: Thromboprophylaxis in Gynaecology Surgery
                    </strong>
                  </p>
                  <p className="mb-2">
                    <em>Dr P Kannappan</em>
                  </p>
                  <p className="mb-2">Q&amp;A</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="mb-2">
                    <strong>113</strong>
                    <strong>0</strong>
                    <strong>-1</strong>
                    <strong>2</strong>
                    <strong>1</strong>
                    <strong>5</strong>
                  </p>
                  <p className="mb-2">1130-1200</p>
                  <p className="mb-2">1200-1215</p>
                </td>
                <td>
                  <p className="mb-2">
                    <strong>S</strong>
                    <strong>ympo</strong>
                    <strong>s</strong>
                    <strong>i</strong>
                    <strong>u</strong>
                    <strong>m 10</strong>
                  </p>
                  <p className="mb-2">
                    <strong>Bayer Sponsored Symposium</strong>
                  </p>
                  <p className="mb-2">Moderator: Dr Patricia Lim Su-Lyn</p>
                  <p className="mb-2">
                    <strong>
                      What is the Role of LNG-IUS in Women&rsquo;s Healthcare
                      during the COVID-19 Pandemic?
                    </strong>
                  </p>
                  <p className="mb-2">
                    <em>Dr Delfin Tan&nbsp;</em>
                  </p>
                  <p className="mb-2">Q&amp;A</p>
                </td>
                <td>
                  <p className="mb-2">
                    <strong>
                      Video Presentations - <em>Obstetrics &amp; Gynaecology </em>
                    </strong>
                  </p>
                  <p className="mb-2">
                    <em>(5 min per presentation, 2 min Q&amp;A)</em>
                  </p>
                  <p className="mb-2">
                    <strong>V 01 </strong>
                  </p>
                  <p className="mb-2">
                    <strong>
                      Preconception Laparoscopic Transabdominal Cerclage{' '}
                    </strong>
                  </p>
                  <p className="mb-2">
                    <em>Dr Nurhayati Alias</em>
                  </p>
                  <p className="mb-2">
                    <strong>V 02</strong>
                  </p>
                  <p className="mb-2">
                    <strong>
                      Relationship between Maternal Serum Levels of 25(OH)D in the
                      First Trimester and Vitamin D Status with Daily Vitamin D
                      Dietary Intake and Adverse Pregnancy Outcomes
                    </strong>
                  </p>
                  <p className="mb-2">
                    <em>Dr Nor Haslinda Binti Abd Aziz</em>
                  </p>
                  <p className="mb-2">
                    <strong>V 03</strong>
                  </p>
                  <p className="mb-2">
                    <strong>
                      Negative Psychological Response towards COVID-19 among
                      Obstetric Patients in Malaysia
                    </strong>
                  </p>
                  <p className="mb-2">
                    <em>Dr Aida Hani Mohd Kalok</em>
                  </p>
                  <p className="mb-2">
                    <strong>V 04</strong>
                  </p>
                  <p className="mb-2">
                    <strong>
                      Preeclampsia in the Era of Universal Screening and Aspirin
                      Prophylaxis: A Multicentre, Observational Study
                    </strong>
                  </p>
                  <p className="mb-2">
                    <em>Dr Voon Hian Yan</em>
                  </p>
                  <p className="mb-2">
                    <strong>V 05</strong>
                  </p>
                  <p className="mb-2">
                    <strong>
                      Using Foley&rsquo;s Catheter as a Tourniquet for
                      Laparoscopic Myomectomy
                    </strong>
                  </p>
                  <p className="mb-2">
                    <em>Dr Sevellaraja Supermaniam</em>
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="mb-2">
                    <strong>121</strong>
                    <strong>5-1300</strong>
                  </p>
                  <p className="mb-2">1215-1245</p>
                  <p className="mb-2">1245-1300</p>
                </td>
                <td>
                  <p className="mb-2">
                    <strong>OGSM Ple</strong>
                    <strong>n</strong>
                    <strong>a</strong>
                    <strong>r</strong>
                    <strong>y 7</strong>
                  </p>
                  <p className="mb-2">Moderator: Prof Zaleha Abdullah Mahdy</p>
                  <p className="mb-2">
                    <strong>
                      Creating Doctors in a Competitive and Digitalized World{' '}
                    </strong>
                  </p>
                  <p className="mb-2">
                    <em>Prof Dato&rsquo; Dr Adeeba Kamarulzaman </em>
                  </p>
                  <p className="mb-2">Q&amp;A</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="mb-2">1300-1315</p>
                </td>
                <td>
                  <p className="mb-2">Break and Sponsored Videos</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="mb-2">
                    <strong>131</strong>
                    <strong>5</strong>
                    <strong>-1</strong>
                    <strong>4</strong>
                    <strong>00</strong>
                  </p>
                  <p className="mb-2">1315-1345</p>
                  <p className="mb-2">1345-1400</p>
                </td>
                <td>
                  <p className="mb-2">
                    <strong>S</strong>
                    <strong>ympo</strong>
                    <strong>s</strong>
                    <strong>i</strong>
                    <strong>u</strong>
                    <strong>m 11</strong>
                  </p>
                  <p className="mb-2">
                    <strong>MSD Sponsored Symposium</strong>
                  </p>
                  <p className="mb-2">Moderator: Dr Nicholas Ngeh</p>
                  <p className="mb-2">
                    <strong>HPV Prevention: Close the Age Gap</strong>
                  </p>
                  <p className="mb-2">
                    <em>Dr Philip Kho</em>
                  </p>
                  <p className="mb-2">Q&amp;A</p>
                </td>
                <td>
                  <p className="mb-2">
                    <strong>S</strong>
                    <strong>ympo</strong>
                    <strong>si</strong>
                    <strong>u</strong>
                    <strong>m 12</strong>
                  </p>
                  <p className="mb-2">
                    <strong>StemLife Sponsored Symposium</strong>
                  </p>
                  <p className="mb-2">Moderator: Mr Tan Thean Heng</p>
                  <p className="mb-2">
                    <strong>Stemlife: The Better Choice of NIPT</strong>
                  </p>
                  <p className="mb-2">
                    <em>Dr Hyuk-jung Kwon </em>
                  </p>
                  <p className="mb-2">Q&amp;A</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="mb-2">
                    <strong>140</strong>
                    <strong>0</strong>
                    <strong>-1</strong>
                    <strong>445</strong>
                  </p>
                  <p className="mb-2">1400-1430</p>
                  <p className="mb-2">1430-1445</p>
                </td>
                <td>
                  <p className="mb-2">
                    <strong>OGSM Surgical Tutorial 2</strong>
                  </p>
                  <p className="mb-2">Moderator:Dr Tan Yiap Loong</p>
                  <p className="mb-2">
                    <strong>
                      Repair of Perineal Tears: Urogynae Tutorial on Third and
                      Fourth Degree Perineal Tears
                    </strong>
                  </p>
                  <p className="mb-2">
                    <em>Assoc Prof Ixora Kamisan Atan </em>
                  </p>
                  <p className="mb-2">Q&amp;A</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="mb-2">
                    <strong>144</strong>
                    <strong>5</strong>
                    <strong>-1</strong>
                    <strong>5</strong>
                    <strong>30</strong>
                  </p>
                  <p className="mb-2">1445-1515</p>
                  <p className="mb-2">1515-1530</p>
                </td>
                <td>
                  <p className="mb-2">
                    <strong>Sy</strong>
                    <strong>m</strong>
                    <strong>po</strong>
                    <strong>si</strong>
                    <strong>u</strong>
                    <strong>m 13</strong>
                  </p>
                  <p className="mb-2">
                    <strong>Zuellig Pharma </strong>
                    <strong>Sponsored Symposium</strong>
                  </p>
                  <p className="mb-2">Moderator: Dr Premitha Damodaran</p>
                  <p className="mb-2">
                    <strong>
                      Iron Deficiency Anemia In Women: How To Stem The Rising Tide
                    </strong>
                  </p>
                  <p className="mb-2">
                    <em>Prof Felice Petraglia&nbsp;</em>
                  </p>
                  <p className="mb-2">Q&amp;A</p>
                </td>
                <td>
                  <p className="mb-2">
                    <strong>Sy</strong>
                    <strong>m</strong>
                    <strong>po</strong>
                    <strong>si</strong>
                    <strong>u</strong>
                    <strong>m 14</strong>
                  </p>
                  <p className="mb-2">
                    <strong>AbbVie Sponsored Symposium </strong>
                  </p>
                  <p className="mb-2">Moderator: Dr Shilpa Nambiar</p>
                  <p className="mb-2">
                    <strong>
                      Rethinking Management of Endometriosis: Are we Advancing
                      Forward?&nbsp;
                    </strong>
                  </p>
                  <p className="mb-2">
                    <em>Assoc Prof Mukhri Hamdan </em>
                  </p>
                  <p className="mb-2">Q&amp;A</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="mb-2">1530-1545</p>
                </td>
                <td>
                  <p className="mb-2">Break &amp; Sponsored Videos</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="mb-2">
                    <strong>154</strong>
                    <strong>5</strong>
                    <strong>-1</strong>
                    <strong>6</strong>
                    <strong>15</strong>
                  </p>
                  <p className="mb-2">
                    <strong>161</strong>
                    <strong>5</strong>
                    <strong>-1</strong>
                    <strong>6</strong>
                    <strong>45</strong>
                  </p>
                  <p className="mb-2">1645-1700</p>
                </td>
                <td>
                  <p className="mb-2">
                    <strong>OGSM Plenary 8</strong>
                  </p>
                  <p className="mb-2">Moderator: Dr Eeson Sinthamoney</p>
                  <p className="mb-2">
                    <strong>
                      Fertility Preservation: Current and Future Perspectives{' '}
                    </strong>
                  </p>
                  <p className="mb-2">
                    <em>Assoc Prof Kate Stern </em>
                  </p>
                  <p className="mb-2">
                    <strong>OGSM Plenary 9</strong>
                  </p>
                  <p className="mb-2">Moderator: Dr Eeson Sinthamoney</p>
                  <p className="mb-2">
                    <strong>
                      Redefining Intrapartum Care based on Recent Evidence
                    </strong>
                  </p>
                  <p className="mb-2">
                    <em>Prof Sir S Arulkumaran</em>
                  </p>
                  <p className="mb-2">Q&amp;A</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="mb-2">1700-1715</p>
                </td>
                <td>
                  <p className="mb-2">Break &amp; Sponsored Videos</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="mb-2">
                    <strong>171</strong>
                    <strong>5</strong>
                    <strong>-1830</strong>
                  </p>
                </td>
                <td>
                  <p className="mb-2">
                    <strong>OGSM Challenge: Grand Finale</strong>
                  </p>
                  <p className="mb-2">
                    <strong>OGSM Vs OGSS Vs RANZCOG</strong>
                  </p>
                  <p className="mb-2">Quizmaster: Dr Muniswaran Ganeshan</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="mb-2">
                    <strong>1830 - 1845</strong>
                  </p>
                </td>
                <td>
                  <p className="mb-2">
                    <strong>Closing Ceremony</strong>
                  </p>
                  <p className="mb-2">
                    <em>Award Presentations </em>
                  </p>
                  <p className="mb-2">
                    <em>Treasure Hunt Lucky Winners</em>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </Tab>
      </Tabs>
    </Wrapper>
  )
}
