import React from 'react'
import styled from 'styled-components'
import { Switch, Route } from 'react-router-dom'

import WelcomePage from '../pages/WelcomePage'
import LoginPage from '../pages/LoginPage'
import entranceBg from '../images/entrance-bg.jpg'

const Page = styled.div`
  background: url(${entranceBg}) no-repeat center center fixed;
  background-size: cover;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
`

export default function EntrancePages({ children }) {
  return (
    <Page>
      <Switch>
        <Route exact path="/" component={WelcomePage} />
        <Route path="/login" component={LoginPage} />
      </Switch>
    </Page>
  )
}
