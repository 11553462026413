import React from 'react'
import styled from 'styled-components'
import { SIZES } from '../styles/theme'

const Wrapper = styled.div`
  background: black;

  .underlay {
    height: 100vh;
    display: inline-block;
    position: relative;

    &#lobby {
      .portrait {
        display: none;

        ${SIZES.portrait} {
          display: inline-block;
        }
      }

      .landscape {
        display: inline-block;

        ${SIZES.portrait} {
          display: none;
        }
      }
    }

    .bg-img {
      position: relative;
      width: 100vw;
      height: 100%;
      pointer-events: none;
    }
  }
`

export default function PageWithBg({ children, bgImg, portraitBg }) {
  return (
    <Wrapper>
      <div id={portraitBg ? 'lobby' : 'others'} className="underlay">
        <img className="bg-img landscape" src={bgImg} alt="Page Background" />
        {portraitBg && (
          <img className="bg-img portrait" src={portraitBg} alt="Page Background" />
        )}
        {children}
      </div>
    </Wrapper>
  )
}
