import React from 'react'
import styled from 'styled-components'
import { Nav, Navbar } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import Logo from '../images/logo.png'
import LogoSM from '../images/logo-sm.png'
import AttentionButton from './AttentionButton'
import TreasureHunt from './TreasureHunt'
import Qr from './Qr'
import { logoutUser } from '../ducks/auth'
import { SIZES } from '../styles/theme'

const StyledNavbar = styled(Navbar)`
  ${(props) => {
    if (props.fixed) {
      return `background: rgba(255, 255, 255, 0.5);`
    } else {
      return `
        background: rgb(236, 247, 246);
        background: linear-gradient(
          90deg,
          rgba(236, 247, 246, 1) 0%,
          rgba(240, 220, 223, 1) 100%
        );
      `
    }
  }};
  ${SIZES.dynamicTopNav};

  .navbar-brand {
    height: 100%;
    padding: 0;
    @media (max-width: 450px) {
      display: none;
    }
    .logo {
      &.big {
        height: 100%;
        display: block;
      }
    }
  }

  .navbar-nav {
    align-items: center;
  }

  #logout-btn {
    color: #fff;
    font-weight: 400;
    font-size: 0.85em;

    background-color: #5a66a7;
    padding: 8px 12px;
    border: none;
    margin-left: .8em;

    border-radius: 5px;
    text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 6px 0px #3c4a9a, 0px 3px 15px rgba(255, 255, 255, 0),
      inset 0px 1px 0px rgba(255, 255, 255, 0),
      inset 0px 0px 3px rgba(255, 255, 255, 0);

    transition: all 0.2s ease-in-out;

    &:active {
      box-shadow: 0px 2px 0px #3c4a9a, 0px 1px 6px rgba(255, 255, 255, 0),
        inset 0px 1px 0px rgba(255, 255, 255, 0.3),
        inset 0px 0px 3px rgba(255, 255, 255, 0.5);
    }

    ${SIZES.mobile} {
      font-size: 0.6em;
      padding: 4px 6px;
    }
  }
`

export default function TopNav({ fixedNav, showPw }) {
  const dispatch = useDispatch()

  const handleLogout = () => {
    dispatch(logoutUser())
  }

  return (
    <>
      <TreasureHunt />
      <Qr showQr />
      <StyledNavbar {...(fixedNav ? { fixed: 'top' } : {})}>
        <Navbar.Brand as={Link} to="/lobby">
          <img className="logo big" src={Logo} alt="Logo" />
          {/* <img className="logo small" src={LogoSM} alt="Logo" /> */}
        </Navbar.Brand>

        <Nav className="ml-auto h-100">
          {showPw && (
            <div id="live-pw">
              <div>Password To Watch:</div>
              <h6 className="pw">ogsm1234</h6>
            </div>
          )}
          <AttentionButton />
          <AttentionButton isQr />
          <Nav.Link id="logout-btn" onClick={handleLogout}>
            Sign Out
          </Nav.Link>
          {/* <Nav.Link href="#home">Home</Nav.Link>
        <NavDropdown title="Dropdown" id="basic-nav-dropdown">
          <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
          <NavDropdown.Item href="#action/3.2">
            Another action
          </NavDropdown.Item>
          <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
          <NavDropdown.Divider />
          <NavDropdown.Item href="#action/3.4">Sign</NavDropdown.Item>
        </NavDropdown> */}
        </Nav>
      </StyledNavbar>
    </>
  )
}
