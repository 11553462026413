import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import ReactGA from 'react-ga'

import NoticeBar from '../components/NoticeBar'
import PageWithBg from '../components/PageWithBg'
import HallOverlay from '../components/HallOverlay'

// import bluePin from '../images/pin-blue.png'
import lobbyBg from '../images/lobby-bg.jpg'
import lobbyPortrait from '../images/lobby-portrait.jpg'
import pinkPin from '../images/pin-pink.png'
import { useDispatch, useSelector } from 'react-redux'
import bgMusic from '../images/bgmusic.mp3'
import { showSchedule } from '../ducks/layout'
import { SIZES } from '../styles/theme'
// import PageDrawer from '../components/PageDrawer'

const PageWrapper = styled.div`
  #virtual-tour-pin {
    top: 40%;
    left: 70.3%;
  }

  #hall-pin {
    top: 19%;
    left: 34.3%;
  }

  #schedule-pin {
    top: 54.2%;
    left: 22.4%;
  }

  #recording-pin {
    top: 38.8%;
    left: 24.1%;
  }

  ${SIZES.portrait} {
    #virtual-tour-pin {
      top: 41.1%;
      left: 82.9%;
    }

    #hall-pin {
      top: 25.7%;
      left: 19.9%;
    }

    #schedule-pin {
      top: 57.7%;
      left: 67.9%;
    }

    #recording-pin {
      top: 41.5%;
      left: 8.6%;
    }
  }

  button {
    background-image: url(${pinkPin});
    background-color: transparent;
    border: none;
  }

  .pins {
    cursor: pointer;
    position: absolute;
    width: 7%;
    height: 7%;
    background-image: url(${pinkPin});
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
    animation: heartbeat 7s infinite;

    &:hover {
      animation-name: bounce;
      animation-duration: 1s;
      animation-fill-mode: both;

      .pin-text {
        display: block;
      }
    }

    .pin-text {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 80%;
      white-space: nowrap;
      display: none;

      h5 {
        color: white;
        text-shadow: 1px 1px 1px #000;
      }
    }
  }
`

export default function LobbyPage({ currentUser }) {
  const { muted } = useSelector((state) => state.layout)
  const dispatch = useDispatch()
  const [overlay, setOverlay] = useState(null)

  const displaySchedule = () => {
    dispatch(showSchedule())
  }

  const showConferenceOverlay = () => setOverlay('conference')
  const showTourOverlay = () => setOverlay('hall')
  const hideOverlay = () => setOverlay(null)

  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      hideOverlay()
    }
  }, [])

  useEffect(() => {
    ReactGA.initialize('UA-176909815-1', {
      gaOptions: {
        userId: currentUser.id,
      },
    })
  }, [currentUser.id])

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false)

    return () => {
      document.removeEventListener('keydown', escFunction, false)
    }
  }, [escFunction])

  return (
    <PageWrapper>
      <NoticeBar />
      <audio
        loop
        autoPlay
        muted={muted}
        style={{ visibility: 'hidden' }}
        src={bgMusic}
      ></audio>
      <PageWithBg bgImg={lobbyBg} portraitBg={lobbyPortrait}>
        <HallOverlay overlay={overlay} hideOverlay={hideOverlay} />
        <button
          id="virtual-tour-pin"
          onClick={showTourOverlay}
          className="pins"
        >
          <div className="pin-text">
            <h5>Enter 3D Virtual Hall</h5>
          </div>
        </button>

        <button id="hall-pin" onClick={showConferenceOverlay} className="pins">
          <div className="pin-text">
            <h5>Enter Conference Hall</h5>
          </div>
        </button>
        <button id="schedule-pin" onClick={displaySchedule} className="pins">
          <div className="pin-text">
            <h5>View Programme Schedule</h5>
          </div>
        </button>
        <Link to="/recordings" id="recording-pin" className="pins">
          <div className="pin-text">
            <h5>Watch Recorded Conference</h5>
          </div>
        </Link>
      </PageWithBg>
    </PageWrapper>
  )
}
