import React, { useEffect } from 'react'
import styled from 'styled-components'
import ReactGA from 'react-ga'

import { SIZES } from '../styles/theme'

const Page = styled.div`
  ${SIZES.dynamicContentHeight};
  width: 100vw;
  overflow: hidden;

  iframe {
    height: 100%;
    width: 100%;
  }
`

export default function TourPage({ tourUrl, hallNumber }) {
  useEffect(() => {
    ReactGA.pageview(`/exhibition-tour-${hallNumber}`)
  }, [hallNumber])

  return (
    <Page>
      <iframe title="OGSM 3D Tour" src={tourUrl} frameBorder="0" />
    </Page>
  )
}
