const dev = {
  apiUrl: 'http://localhost:3000',
}

const prod = {
  apiUrl: 'https://cloudexpo-server.herokuapp.com',
}

const config = process.env.REACT_APP_STAGE === 'production' ? prod : dev

export default {
  // Add common config values here
  // MAX_ATTACHMENT_SIZE: 5000000,
  // gsheetQuestionApi: 'https://script.google.com/macros/s/AKfycbycE5S9_9nqJCaOz-gTzYoSkWYAdQvesMMoCr4QAnSYl9Bbqg/exec',
  gsheetQuestionApi: 'https://script.google.com/macros/s/AKfycbzWqe4JgaKmJ2ukTwG0V-vp5TBCg2VaPeTIhQTWjRDCaUogdzI/exec',
  ...config,
}
