const COLORS = {
  red: '#FF0016',
  redDark: '#a3000e',
  redTrans: 'rgba(255, 0, 22, .8)',

  darkblue: '#1d194a',
  blue: '#031E88',
  purple: '#3A2D86',

  lightGray: '#F2F2F2',
  lightGray1: '#B1B1B1',

  text: '#404040',

  pink: '#d1586a',
  darkPink: '#F5216A',

  cyan: '#26B4A9',
}

const FONTS = {
  primary: "'Montserrat', sans-serif;",
  secondary: "'Montserrat', sans-serif;",
}

const _topNavLaptop = '65px'
const _topNavMobile = '40px'
const _mobileHeightThreshold = '450px'
const _mobileWidthThreshold = '450px'

const SIZES = {
  mobile: `@media (max-height: ${_mobileHeightThreshold}), (max-width: ${_mobileWidthThreshold})`,
  portrait: `@media (max-width: 567px)`,

  dynamicTopNav: `
    height: ${_topNavLaptop};
    @media (max-height: ${_mobileHeightThreshold}) {
      height: ${_topNavMobile};
    }
  `,

  dynamicContentHeight: `
    height: calc(100vh - ${_topNavLaptop});
    @media (max-height: ${_mobileHeightThreshold}) {
      height: calc(100vh - ${_topNavMobile});
    }
  `,

  dynamicContentMinHeight: `
    min-height: calc(100vh - ${_topNavLaptop});
    @media (max-height: ${_mobileHeightThreshold}) {
      min-height: calc(100vh - ${_topNavMobile});
    }
  `,

  dynamicTop: `
    top: calc(${_topNavLaptop} + 5px);
    @media (max-height: ${_mobileHeightThreshold}) {
      top: calc(${_topNavMobile} + 5px);
    }
  `,

  dynamicTop2: `
    top: calc(${_topNavLaptop});
    @media (max-height: ${_mobileHeightThreshold}) {
      top: calc(${_topNavMobile});
    }
  `,

  dynamicTopWithOffset: function (offset) {
    return `
      top: calc(${_topNavLaptop} + ${offset}px);
      @media (max-height: ${_mobileHeightThreshold}) {
        top: calc(${_topNavMobile} + ${offset}px);
      }
    `
  },
}

export { COLORS, FONTS, SIZES }
