import React, { useEffect } from 'react'
import { Route, Redirect } from 'react-router-dom'
import TopNav from '../components/TopNav'
import { useDispatch } from 'react-redux'
import { refreshUser } from '../ducks/auth'

export default function AdminRoute({
  fixedNav = false,
  component: Component,
  authed,
  currentUser,
  ...rest
}) {
  const dispatch = useDispatch()

  useEffect(() => {
    if (authed) {
      dispatch(refreshUser())
    }
  }, [authed, dispatch])

  return (
    <Route
      {...rest}
      render={(props) =>
        authed && currentUser && currentUser.is_admin ? (
          <>
            <Component {...props} />
          </>
        ) : (
          <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        )
      }
    />
  )
}
