import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Switch, Route } from 'react-router-dom'

import hall1Bg from './images/conference/hall1.png'
import hall2Bg from './images/conference/hall2.png'

import EntrancePages from './containers/EntrancePages'
import PrivateRoute from './containers/PrivateRoute'
import LobbyPage from './pages/LobbyPage'
import RecordingPage from './pages/RecordingPage'
import VimeoPage from './pages/VimeoPage'
import TourPage from './pages/TourPage'
import LivePage from './pages/LivePage'
import CountdownPage from './pages/CountdownPage'
import AdminPage from './pages/AdminPage'
import AdminLuckyDrawPage from './pages/AdminLuckyDrawPage'
import AdminRoute from './containers/AdminRoute'
import ProgrammeModal from './components/ProgrammeModal'

function App() {
  const { currentUser, isAuthenticated } = useSelector((state) => state.auth)

  return useMemo(() => {
    return (
      <>
        <ProgrammeModal />
        <Switch>
          <Route exact path="/enter" component={EntrancePages} />
          <Route exact path="/login" component={EntrancePages} />
          <Route exact path="/" component={EntrancePages} />
          <PrivateRoute
            exact
            fixedNav
            currentUser={currentUser}
            authed={isAuthenticated}
            path="/lobby"
            component={LobbyPage}
            navOptions={[
              'hall1',
              'hall2',
              'tourA',
              'tourB',
              'schedule',
              'luckydraw',
              'audio',
            ]}
          />
          <PrivateRoute
            exact
            currentUser={currentUser}
            authed={isAuthenticated}
            path="/virtual-exhibition-a"
            navOptions={['lobby', 'tourB']}
            component={(props) => (
              <TourPage
                {...props}
                hallNumber={'A'}
                tourUrl="https://staging.webist.name/HallA"
              />
            )}
          />
          <PrivateRoute
            exact
            currentUser={currentUser}
            authed={isAuthenticated}
            path="/virtual-exhibition-b"
            navOptions={['lobby', 'tourA']}
            component={(props) => (
              <TourPage
                {...props}
                hallNumber={'B'}
                tourUrl="https://staging.webist.name/HallB"
              />
            )}
          />
          <PrivateRoute
            exact
            fixedNav
            currentUser={currentUser}
            authed={isAuthenticated}
            path="/conference-hall-1"
            navOptions={['lobby', 'hall2', 'schedule', 'question', 'pw']}
            component={(props) => (
              <LivePage
                {...props}
                hallNumber={1}
                hallBg={hall1Bg}
                liveUrl="https://player.vimeo.com/video/455003335?autoplay=1"
              />
            )}
          />
          <PrivateRoute
            fixedNav
            exact
            currentUser={currentUser}
            authed={isAuthenticated}
            path="/conference-hall-2"
            navOptions={['lobby', 'hall1', 'schedule', 'question', 'pw']}
            component={(props) => (
              <LivePage
                {...props}
                hallNumber={2}
                hallBg={hall2Bg}
                liveUrl="https://player.vimeo.com/video/455006960?autoplay=1"
              />
            )}
          />
          <PrivateRoute
            exact
            currentUser={currentUser}
            authed={isAuthenticated}
            path="/recordings"
            component={RecordingPage}
            navOptions={['lobby']}
          />
          <PrivateRoute
            exact
            currentUser={currentUser}
            authed={isAuthenticated}
            path="/recordings/watch"
            component={VimeoPage}
            navOptions={['back']}
          />
          <AdminRoute
            exact
            currentUser={currentUser}
            authed={isAuthenticated}
            path="/admin"
            component={AdminPage}
          />
          <AdminRoute
            exact
            currentUser={currentUser}
            authed={isAuthenticated}
            path="/admin/lucky-draw"
            component={AdminLuckyDrawPage}
          />
        </Switch>
      </>
    )
  }, [currentUser, isAuthenticated])
}

export default App
