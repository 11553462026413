import React from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'

import qrCode from '../images/cpdqr.png'
import scanIcon from '../images/navigation/scan.png'
import { COLORS, SIZES } from '../styles/theme'
import {
  toggleQrDropdown,
  hideQrDropdown,
  hideThDropdown,
} from '../ducks/layout'

const Quiz = styled.section`
  z-index: 2000;
  position: fixed;
  ${SIZES.dynamicTop};
  right: 5%;
  width: 95%;
  max-width: 400px;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 12px;
  max-height: 80vh;
  overflow: scroll;
  ${SIZES.portrait} {
    right: 50%;
    transform: translateX(50%);
    font-size: 0.9em;
  }

  .hide-btn {
    position: absolute;
    top: 6px;
    right: 8px;
    border: none;
    background: none;
    color: white;
  }

  .onboard-wrapper {
    position: relative;
    padding: 20px;
    .rules {
      font-weight: bold;
      li {
        margin-bottom: 0.4em;
      }
    }
  }

  #scan-icon {
    height: 45px;
    width: 45px;
    border-radius: 50%;
    display: block;
    margin: 10px auto;
  }
`

export default function QrModal({ showQr }) {
  const dispatch = useDispatch()

  const qrDropdown = useSelector((state) => state.layout.qrDropdown)

  const hideBtn = (isWhite) => (
    <button
      style={{ color: isWhite ? 'white' : COLORS.purple }}
      className="hide-btn"
      onClick={() => {
        dispatch(hideQrDropdown())
      }}
    >
      &#10005;
    </button>
  )

  if (!qrDropdown) return null

  return (
    <Quiz>
      <div className="onboard-wrapper text-center">
        {hideBtn()}
        {showQr ? (
          <>
            <h6 className="my-4">
              Scan this QR with your <span className="text-red">MMA CPD App</span> to
              claim CPD Points.
            </h6>
            <img height="250" width="250" src={qrCode} alt="MMA CPD QR" />
          </>
        ) : (
          <>
            <img id="scan-icon" src={scanIcon} alt="scan" />
            <h6>
              Come back here again after the closing ceremony to access your QR
              code for CPD!
            </h6>
          </>
        )}
      </div>
    </Quiz>
  )
}
