import React, { useState } from 'react'
import styled from 'styled-components'

import { COLORS } from '../styles/theme'
import { useDispatch, useSelector } from 'react-redux'
import { loginUser, registerUser } from '../ducks/auth'
import { Redirect, Link } from 'react-router-dom'
import ForgetPassword from '../components/ForgetPassword'

const LoginBox = styled.section`
  position: fixed;
  bottom: 0;
  right: 5vw;
  padding: 6vw;
  padding-bottom: 0;
  max-height: 100%;
  overflow: scroll;

  background-color: ${COLORS.redTrans};
  text-align: center;
  color: white;

  .action-text {
    font-weight: bold;
    color: ${COLORS.red};
    cursor: pointer;

    &:hover {
      color: ${COLORS.redTrans};
    }
  }

  form {
    background: white;
    padding: 30px 10px;
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
    margin: auto;
    margin-top: 50px;
    max-width: 400px;

    input {
      letter-spacing: 1px;
      width: 80%;
      background: ${COLORS.lightGray};
      border: none;
      padding: 12px 32px;
      border-radius: 25px;
      margin-top: 20px;

      &::placeholder {
        color: ${COLORS.lightGray1};
      }
    }

    #tel-input {
      position: relative;

      input {
        margin-top: 0;
        padding-left: 80px;
      }

      .tel-prefix {
        letter-spacing: 1px;
        color: black;
        position: absolute;
        left: 70px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .forget-pass {
      width: 80%;
      color: ${COLORS.redTrans};
      margin-top: 10px;
      font-size: 0.9em;
    }

    .login-btn {
      display: block;
      width: 80%;
      margin: auto;
      margin-top: 3em;
      margin-bottom: 1em;
      font-size: 1.2em;
      color: white;
      font-weight: bold;
      padding: 10px 30px;
      border-radius: 50px;
      letter-spacing: 1.5px;
      background: rgb(233, 99, 99);
      background: linear-gradient(
        90deg,
        rgba(233, 99, 99, 1) 0%,
        rgba(255, 67, 155, 1) 100%
      );
    }
  }
`

export default function LoginPage() {
  const [phone, setPhone] = useState('')
  const [password, setPassword] = useState('123')
  const [email, setEmail] = useState('tester@demo.com')
  const [loginPage, setLoginPage] = useState(true)

  const { isFetching, isAuthenticated } = useSelector((state) => state.auth)
  const dispatch = useDispatch()

  const switchForm = () => {
    setLoginPage(!loginPage)
    setPassword('')
  }

  const handleLogin = (e) => {
    e.preventDefault()
    dispatch(
      loginUser({
        email,
        password,
      })
    )
  }

  const handleRegister = (e) => {
    e.preventDefault()
    dispatch(
      registerUser({
        phone,
        email,
        password,
      })
    )
  }

  const onPhoneInput = (e) => {
    const { value } = e.target
    if (isNaN(value)) return
    setPhone(e.target.value)
  }

  const onPasswordInput = (e) => {
    setPassword(e.target.value)
  }

  const onEmailInput = (e) => {
    setEmail(e.target.value)
  }

  if (isAuthenticated) {
    return <Redirect to="/lobby" />
  } else {
    return (
      <LoginBox>
        <h3>OGSM Virtual Scientific Conference 2020</h3>
        {loginPage ? (
          <>
            <p>Please login to continue</p>
            <form onSubmit={handleLogin}>
              <input
                type="email"
                placeholder="Email Address"
                value={email}
                onChange={onEmailInput}
              />
              <input
                type="password"
                value={password}
                placeholder="Password"
                onChange={onPasswordInput}
              />
              <input
                disabled={!(email.length && password.length) || isFetching}
                type="submit"
                value={isFetching ? 'LOGGING IN...' : 'LOGIN'}
                className="btn login-btn"
              />
              {/* <p className="text-muted mb-1">
                Don't have account?{' '}
                <span onClick={switchForm} className="action-text">
                  Sign Up
                </span>
              </p> */}
              <ForgetPassword />
            </form>
          </>
        ) : (
          <>
            <p>Please sign up here</p>
            <form onSubmit={handleRegister}>
              <div id="tel-input">
                <div className="tel-prefix">+60</div>
                <input
                  type="tel"
                  placeholder="12345678"
                  value={phone}
                  onChange={onPhoneInput}
                />
              </div>
              <input
                type="email"
                placeholder="E-mail"
                value={email}
                onChange={onEmailInput}
              />
              <input
                type="password"
                value={password}
                placeholder="Password"
                onChange={onPasswordInput}
              />
              <input
                disabled={!(email.length && phone.length && password.length) || isFetching}
                type="submit"
                value={isFetching ? 'REGISTERING...' : 'REGISTER'}
                className="btn login-btn"
              />
              <p className="text-muted">
                Already have an account?{' '}
                <span onClick={switchForm} className="action-text">
                  Log In
                </span>
              </p>
            </form>
          </>
        )}
      </LoginBox>
    )
  }
}
