import React from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import questions from './questions'
import QuizStep from './QuizStep'
import QuizQuestion from './QuizQuestion'
import { COLORS, SIZES } from '../styles/theme'
import { setStep } from '../ducks/quiz'
import { startQuiz } from '../ducks/auth'
import { hideThDropdown } from '../ducks/layout'

const Quiz = styled.section`
  z-index: 2000;
  position: fixed;
  ${SIZES.dynamicTop};
  right: 40px;
  width: 90%;
  max-width: 600px;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 12px;
  max-height: 80vh;
  overflow: scroll;
  ${SIZES.mobile} {
    right: 50%;
    transform: translateX(50%);
  }

  .hide-btn {
    position: absolute;
    top: 6px;
    right: 8px;
    border: none;
    background: none;
    color: white;
  }

  .btn {
    padding: 5px 15px;
    border-radius: 40px;
    letter-spacing: 1px;
  }

  .theme-btn {
    color: white;
    background: ${COLORS.purple};
  }

  .onboard-wrapper {
    position: relative;
    padding: 20px;
    .rules {
      font-weight: bold;
      li {
        margin-bottom: 0.4em;
      }
    }

    #start-btn {
      display: block;
      margin: auto;
      padding: 6px 15px;
      font-size: 1.2em;
    }
  }

  .question-wrapper {
    position: relative;
    header {
      background: ${COLORS.purple};
      padding: 15px 5px;

      .steps {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
      }

      h6 {
        font-weight: bold;
        color: white;
        margin-bottom: 0.9em;
        letter-spacing: 1px;
      }
    }

    article {
      padding: 15px;
    }
  }

  #complete-icon {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    border: solid 2px ${COLORS.pink};
    margin: 20px auto;

    svg {
      height: 60%;
      fill: ${COLORS.pink};
    }
  }
`

export default function TreasureHunt() {
  const dispatch = useDispatch()

  const thDropdown = useSelector((state) => state.layout.thDropdown)
  const { quizAttempt = {}, isFetching } = useSelector((state) => state.auth)
  const { quizStep, answer } = useSelector((state) => state.quiz)

  const goToStep = (step) => dispatch(setStep(step))
  const initiateQuiz = () => dispatch(startQuiz())

  const hideBtn = (isWhite) => (
    <button
      style={{ color: isWhite ? 'white' : COLORS.purple }}
      className="hide-btn"
      onClick={() => {
        dispatch(hideThDropdown())
      }}
    >
      &#10005;
    </button>
  )

  if (!thDropdown) return null

  /**
   * End Treasure Hunt
   */
  // return (
  //   <Quiz>
  //     <div className="onboard-wrapper text-center">
  //       {hideBtn()}
  //       <h6 className="mt-4 font-weight-bold">Treasure Hunt Event Has Ended!</h6>
  //       <p>Stay tuned to the closing ceremony for the Lucky Draw!</p>
  //     </div>
  //   </Quiz>
  // )

  /**
   * Start Treasure Hunt
   */
  let currentQuestion = questions.find((q) => q.id === quizStep)
  let completedAll =
    quizAttempt && Object.values(quizAttempt).every((ans) => Boolean(ans))
  return (
    <Quiz>
      {completedAll ? (
        <div className="onboard-wrapper text-center">
          {hideBtn()}
          <div id="complete-icon" className="flex-center">
            <svg x="0px" y="0px" viewBox="0 0 512 512">
              <g>
                <g>
                  <path
                    d="M504.502,75.496c-9.997-9.998-26.205-9.998-36.204,0L161.594,382.203L43.702,264.311c-9.997-9.998-26.205-9.997-36.204,0
              c-9.998,9.997-9.998,26.205,0,36.203l135.994,135.992c9.994,9.997,26.214,9.99,36.204,0L504.502,111.7
              C514.5,101.703,514.499,85.494,504.502,75.496z"
                  />
                </g>
              </g>
            </svg>
          </div>
          <h4>Good job! You have completed all questions!</h4>
          <h6>
            Stay tuned to the closing ceremony on Sept 6th to find out if you're
            the lucky winner!
          </h6>
        </div>
      ) : !quizAttempt ? (
        <div className="onboard-wrapper">
          {hideBtn()}
          <h3 className="font-weight-bold">
            Welcome to OGSM Virtual Scientific Conference 2020 Treasure Hunt
          </h3>
          <p>
            Answer all 16 questions correctly and you will be eligble to
            participate in the lucky draw!
            <br />4 lucky winners will be randomly drawn from the pool to win
            cash prizes up to RM1000!
          </p>
          <div className="rules mt-4">
            <p>
              <strong>Rules:</strong>
            </p>
            <ol>
              <li>
                There are 16 questions in total, you can find answers to all the
                questions by navigating inside Virtual Exhibition Hall{' '}
                <Link to="/virtual-exhibition-a">A</Link> and{' '}
                <Link to="/virtual-exhibition-b">B</Link>.
              </li>
              <li>
                The answer to each question will be submitted separately, but
                you won't be allowed to modify a submitted answer, so answer
                carefully!
              </li>
              <li>
                You may close this dialogue and come back anytime, so you do not
                have to complete all questions in one session.
              </li>
              <li>
                We will not accept any submission after{' '}
                <span className="text-red">
                  1:00PM Sept 6th, 2020 (GMT+8 Malaysia Time)
                </span>
              </li>
            </ol>
          </div>
          <button
            disabled={isFetching}
            onClick={initiateQuiz}
            className="btn theme-btn mt-4"
            id="start-btn"
          >
            {isFetching ? 'STARTING...' : "READY? LET'S GO!"}
          </button>
        </div>
      ) : (
        <div className="question-wrapper">
          {hideBtn(true)}
          <header>
            <h6 className="text-center">Question {quizStep}/16</h6>
            <div className="steps">
              {[...Array(questions.length)].map((_, index) => (
                <QuizStep
                  key={index}
                  quizAttempt={quizAttempt}
                  goToStep={goToStep}
                  step={index + 1}
                  active={index + 1 === quizStep}
                />
              ))}
            </div>
          </header>
          <article>
            <QuizQuestion
              answer={answer}
              loading={isFetching}
              quizAttempt={quizAttempt}
              quiz={currentQuestion}
            />
          </article>
        </div>
      )}
    </Quiz>
  )
}
