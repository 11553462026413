import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Modal, Button } from 'react-bootstrap'
import { COLORS } from '../styles/theme'
import Programme from './Programme'

export default function ProgrammeModal() {
  const { scheduleModal } = useSelector((state) => state.layout)
  const dispatch = useDispatch()

  const hideSchedule = () => {
    dispatch({
      type: 'HIDE_SCHEDULE',
    })
  }

  return (
    <Modal
      show={scheduleModal}
      onHide={hideSchedule}
      size="xl"
      aria-labelledby="schedule-modal"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="schedule-modal">Conference Programme</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Programme />
      </Modal.Body>
      <Modal.Footer>
        <Button style={{ background: COLORS.blue }} onClick={hideSchedule}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
