import React, { useState } from 'react'
import styled from 'styled-components'
import { SIZES } from '../styles/theme'
import LoadIcon from '../components/LoadIcon'

const Page = styled.div`
  ${SIZES.dynamicContentHeight};
  width: 100vw;
  overflow: hidden;
  background: black;
  position: relative;

  .left-col {
    position: relative;

    .leave-btn {
      position: absolute;
      bottom: 10px;
      left: 30px;
      border: solid rgba(114, 149, 205, 1) 1px;
      background: transparent;
      color: rgba(114, 149, 205, 1);
      padding: 5px 10px;
      display: flex;
      align-items: center;

      &:hover {
        background: rgba(114, 149, 205, 1);
        color: black;
      }
    }
  }
  iframe {
    width: 100%;
    height: 100%;
  }
`

export default function VimeoPage({ location }) {
  const [loading, setLoading] = useState(true)
  return (
    <Page>
      {loading && <LoadIcon />}
      <iframe
        title="vimeo live"
        src={location.state}
        frameBorder="0"
        allow="autoplay; fullscreen"
        allowFullScreen
        onLoad={() => {
          setLoading(false)
        }}
      ></iframe>
    </Page>
  )
}
