import React from 'react'
import styled from 'styled-components'

import treasurePng from '../images/navigation/treasure.png'
import qrPng from '../images/navigation/qr.png'

import { useDispatch } from 'react-redux'
import { toggleThDropdown, toggleQrDropdown } from '../ducks/layout'
import { COLORS, SIZES } from '../styles/theme'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;
  padding: 5px;
  margin: 0 0.5em;
  ${SIZES.mobile} {
    margin: 0 0.3em;
  }
  cursor: pointer;

  .bubble {
    border: solid 1px ${COLORS.redDark};
    color: ${COLORS.redDark};
    text-align: center;
    font-size: 11.5px;
    font-weight: 600;
    padding: 4px;
    border-radius: 3px;
    min-width: 40px;
    /* max-width: 120px; */

    ${SIZES.mobile} {
      padding: 2px;
      font-size: 6.5px;
    }
  }

  .bubble.blue {
    border: solid 1px ${COLORS.purple};
    color: ${COLORS.purple};
  }

  &:hover {
    transition: 0.15s;
    transform: scale(1.05);
  }

  img {
    height: 100%;
    margin-left: 8px;
  }
`

export default function AttentionButton({ isQr = false, noText = false }) {
  const dispatch = useDispatch()

  if (isQr) {
    return (
      <Wrapper
        onClick={() => {
          dispatch(toggleQrDropdown())
        }}
      >
        <div className="bubble blue">
          Scan MMA CPD <br className="d-none d-sm-block" />
          QR Code Here
        </div>
        <img src={qrPng} alt="QR" />
      </Wrapper>
    )
  } else {
    return (
      <Wrapper
        onClick={() => {
          dispatch(toggleThDropdown())
        }}
      >
        {noText ? null : (
          <div className="bubble">
            WIN UP TO RM1000! <br className="d-none d-sm-block" />
            CLICK TO PARTICIPATE
          </div>
        )}
        <img src={treasurePng} alt="Treasure" />
      </Wrapper>
    )
  }
}
