import React from 'react'
import styled from 'styled-components'
import { Row, Col } from 'react-bootstrap'
import ReactGA from 'react-ga'

import { SIZES } from '../styles/theme'

import monitorImg from '../images/conference/monitor.png'
import ogsmBanner from '../images/conference/ogsm-banner.png'

import obsAbs from '../images/abs-covers/1obs-abs.jpg'
import gynaeAbs from '../images/abs-covers/2gynae-abs.jpg'
import vidAbs from '../images/abs-covers/3vid-abs.jpg'
import obsPres from '../images/abs-covers/4obs-pres.jpg'
import gynaePres from '../images/abs-covers/5gynae-pres.jpg'
import vidPres from '../images/abs-covers/6vid-pres.jpg'

import p1 from '../images/speaker-covers/P1.jpg'
import p2 from '../images/speaker-covers/P2.jpg'
import p3 from '../images/speaker-covers/P3.jpg'
import p4 from '../images/speaker-covers/P4.jpg'
import p5 from '../images/speaker-covers/P5.jpg'
import p6 from '../images/speaker-covers/P6.jpg'
import p7 from '../images/speaker-covers/P7.jpg'
import p8 from '../images/speaker-covers/P8.jpg'
import p9 from '../images/speaker-covers/P9.jpg'

import t1 from '../images/speaker-covers/T1.jpg'
import t2 from '../images/speaker-covers/T2.jpg'

import sym1 from '../images/symposium-covers/sym1.jpg'
import sym2 from '../images/symposium-covers/sym2.jpg'
import sym3 from '../images/symposium-covers/sym3.jpg'
import sym4 from '../images/symposium-covers/sym4.jpg'
import sym5 from '../images/symposium-covers/sym5.jpg'
import sym6 from '../images/symposium-covers/sym6.jpg'
import sym7 from '../images/symposium-covers/sym7.jpg'
import sym8 from '../images/symposium-covers/sym8.jpg'
import sym9 from '../images/symposium-covers/sym9.jpg'
import sym10 from '../images/symposium-covers/sym10.jpg'
import sym11 from '../images/symposium-covers/sym11.jpg'
import sym12 from '../images/symposium-covers/sym12.jpg'
import sym13 from '../images/symposium-covers/sym13.jpg'
import sym14 from '../images/symposium-covers/sym14.jpg'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'

const Page = styled.div`
  background: rgb(23, 5, 80);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(54, 6, 92, 1) 100%
  );
  min-height: 100vh;
  position: relative;
  padding-top: 3em;
  padding-bottom: 10em;

  .header {
    padding: 1em 0;
    img {
      display: block;
      margin: auto;
    }

    .title {
      color: white;
      text-transform: uppercase;

      h1 {
        font-size: 2.6em;
        color: #c77dff;
        letter-spacing: 1px;

        ${SIZES.mobile} {
          font-size: 1.5em;
        }
      }
    }

    .badge {
      font-size: 2.1em;
      letter-spacing: 1px;
      margin-top: 0.4em;
      font-weight: 100;
      padding: 10px 20px;
      border-radius: 50px;
      color: white;
      background: rgb(255, 169, 140);
      background: linear-gradient(
        270deg,
        rgba(255, 169, 140, 1) 0%,
        rgba(247, 153, 144, 1) 9%,
        rgba(227, 113, 156, 1) 47%,
        rgba(196, 48, 174, 1) 77%,
        rgba(173, 0, 189, 1) 100%
      );

      ${SIZES.mobile} {
        font-size: 1.2em;
      }
    }
  }

  .recordings {
    img {
      width: 100%;
    }

    a.coming-soon {
      opacity: 0.4;
      cursor: not-allowed;

      :hover img {
        opacity: 1;
      }
    }

    a:hover img {
      opacity: 0.8;
    }
  }
`

const allRecordings = [
  {
    img: obsAbs,
    external: true,
    link:
      'https://drive.google.com/file/d/1M59tdYr0uMsVViTTYaEhcZOzewuWain2/view?usp=sharing',
  },
  {
    img: gynaeAbs,
    external: true,
    link:
      'https://drive.google.com/file/d/10Z6MLcXHWwESimCmqSqfUeJuSHqOEf7l/view?usp=sharing',
  },
  {
    img: vidAbs,
    external: true,
    link:
      'https://drive.google.com/file/d/1Eikrk5J3HzDeg7pubH-n7wyi-7jlcKTJ/view?usp=sharing',
  },
  { img: obsPres, link: 'https://player.vimeo.com/video/455010419' },
  { img: gynaePres, link: 'https://player.vimeo.com/video/455010749' },
  { img: vidPres, link: 'https://player.vimeo.com/video/455204489' },

  { img: p1, link: 'https://player.vimeo.com/video/455003335' },
  { img: p2, link: 'https://player.vimeo.com/video/455003335' },
  { img: p3, link: 'https://player.vimeo.com/video/455117132' },
  { img: p4, link: 'https://player.vimeo.com/video/455117132' },
  { img: p5, link: 'https://player.vimeo.com/video/455202979' },
  { img: p6, link: 'https://player.vimeo.com/video/455202979' },
  { img: p7, link: 'https://player.vimeo.com/video/455204746' },
  { img: p8, link: 'https://player.vimeo.com/video/455206440' },
  { img: p9, link: 'https://player.vimeo.com/video/455206440' },
  { img: t1, link: 'https://player.vimeo.com/video/455008816' },
  { img: t2, link: 'https://player.vimeo.com/video/455205411' },

  { img: sym1, link: 'https://player.vimeo.com/video/455006960' },
  { img: sym2, link: 'https://player.vimeo.com/video/455007494' },
  { img: sym3, link: 'https://player.vimeo.com/video/455007494' },
  { img: sym4, link: 'https://player.vimeo.com/video/455003866' },
  { img: sym5, link: 'https://player.vimeo.com/video/455004535' },
  { img: sym6, link: 'https://player.vimeo.com/video/455009343' },
  { img: sym7, link: 'https://player.vimeo.com/video/455206971' },
  { img: sym8, link: 'https://player.vimeo.com/video/455203719' },
  { img: sym9, link: 'https://player.vimeo.com/video/455203903' },
  { img: sym10, link: 'https://player.vimeo.com/video/455204096' },
  { img: sym11, link: 'https://player.vimeo.com/video/455205065' },
  { img: sym12, link: 'https://player.vimeo.com/video/455205310' },
  { img: sym13, link: 'https://player.vimeo.com/video/455205931' },
  { img: sym14, link: 'https://player.vimeo.com/video/455206235' },
]

function sortFn(x, y) {
  return x.link === y.link ? 0 : x.link ? -1 : 1
}

export default function RecordingPage() {
  useEffect(() => {
    ReactGA.pageview('/recordings-page')
  }, [])

  return (
    <Page>
      <Row noGutters className="header">
        <Col className="d-none d-md-block" md={5}>
          <img width="80%" src={ogsmBanner} alt="headphone" />
        </Col>
        <Col
          xs={{ span: 12, order: 'first' }}
          md={4}
          className="d-flex justify-content-center align-items-center p-0 p-md-3"
        >
          <div className="title mb-5 mb-md-0">
            <h1>RECORDINGS</h1>
            <div className="badge d-block mx-auto d-md-inline-block">
              PROGRAMME
            </div>
          </div>
        </Col>
        <Col md={3} className="d-none d-md-block">
          <img width="80%" src={monitorImg} alt="monitor" />
        </Col>
      </Row>

      <div className="p-2 p-md-4">
        <Row className="recordings" noGutters>
          {allRecordings.map((recording, index) => {
            const { img, link, external } = recording
            return (
              <Col xs={6} md={4} lg={3} xl={2} key={index} className="p-2 p-md-3">
                {external ? (
                  <a
                    href={link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={link ? '' : 'coming-soon'}
                  >
                    <img src={img} alt="Symposium Thumbnail" />
                  </a>
                ) : (
                  <Link
                    to={{
                      pathname: link ? '/recordings/watch' : '/recordings',
                      state: link,
                    }}
                    className={link ? '' : 'coming-soon'}
                  >
                    <img src={img} alt="Symposium Thumbnail" />
                  </Link>
                )}
              </Col>
            )
          })}
        </Row>
      </div>
    </Page>
  )
}
