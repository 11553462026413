import React from 'react'
import styled from 'styled-components'
import { SIZES } from '../styles/theme'

const Wrapper = styled.div`
  marquee {
    position: fixed;
    ${SIZES.dynamicTop2};
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: 300;
    color: white;
    font-size: 16px;
    /* font-weight: 700; */
    letter-spacing: 1px;
    padding: 2px 0;

    span {
      margin: 0 0.8em;
    }
  }
`

export default function NoticeBar() {
  return (
    <Wrapper>
      <marquee scrollamount={10}>
        <span className="font-weight-bold">
          OGSM First Virtual Scientific Conference 2020 has come to an end. We
          want to thank you for your participations! See you next year!
        </span>
      </marquee>
    </Wrapper>
  )
}
