import React, { useEffect } from 'react'
import { Route, Redirect } from 'react-router-dom'
import TopNav from '../components/TopNav'
import FloatingNav from '../components/FloatingNav'
import { useDispatch } from 'react-redux'
import { refreshUser } from '../ducks/auth'

export default function PrivateRoute({
  fixedNav = false,
  component: Component,
  authed,
  currentUser,
  navOptions,
  ...rest
}) {
  const dispatch = useDispatch()

  useEffect(() => {
    if (authed) {
      dispatch(refreshUser())
    }
  }, [authed, dispatch])
  return (
    <Route
      {...rest}
      render={(props) =>
        authed ? (
          currentUser ? (
            <>
              <TopNav showPw={rest.path.startsWith('/conference-hall')} fixedNav={fixedNav} />
              <Component currentUser={currentUser} {...props} />
              <FloatingNav navOptions={navOptions}/>
            </>
          ) : (
            <Redirect to={{ pathname: '/', state: { from: props.location } }} />
          )
        ) : (
          <Redirect
            to={{ pathname: '/login', state: { from: props.location } }}
          />
        )
      }
    />
  )
}
